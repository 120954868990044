<script>
import Map from 'ol/Map.js';
import {fromLonLat} from 'ol/proj';
import OSM from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile.js';
import View from 'ol/View.js';

//import "ol/ol.css"
//Waypoints:
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Icon, Style} from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import {click} from 'ol/events/condition';
import Select from 'ol/interaction/Select';

export default {
  name: 'MapContainer',
  components: {},
  props: {},
  data() {
    return {
      actuators: { 
        1: [false, true, false, false, false, false],
        2: [false, false, false, false, false, false],
        3: [false, false, true, false, false, false],
        4: [false, false, false, false, false, false],
        5: [false, true, true, false, false, false],
        6: [false, false, false, false, false, false],
        7: [false, false, false, true, false, false],
        8: [false, false, false, false, false, false],
        9: [false, true, false, false, false, false],
        10: [false, false, false, false, false, false],
      },
    };
  },
  mounted() {
   // Configura el popup
   const popup = new Overlay({
      element: document.getElementById('popup'),
      positioning: 'bottom-center',
      stopEvent: true, // Evita que el popup se cierre en eventos
    });

    const map = new Map({
      target: this.$refs['map'],
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-68, -38.9]),
        zoom: 11,
        constrainResolution: true
      }),
      overlays: [popup],
    });
    
    
    // Crear los waypoints
    const waypoints = [
  { id: 1, coordinates: [-68.0712, -38.9635], name: 'Dispositivo 1' },
  { id: 2, coordinates: [-68.0645, -38.9554], name: 'Dispositivo 2' },
  { id: 3, coordinates: [-68.0453, -38.9612], name: 'Dispositivo 3' },
  { id: 4, coordinates: [-68.0499, -38.9395], name: 'Dispositivo 4' },
  { id: 5, coordinates: [-68.0837, -38.9507], name: 'Dispositivo 5' },
  { id: 6, coordinates: [-68.0386, -38.9463], name: 'Dispositivo 6' },
  { id: 7, coordinates: [-68.0567, -38.9724], name: 'Dispositivo 7' },
  { id: 8, coordinates: [-68.0733, -38.9419], name: 'Dispositivo 8' },
  { id: 9, coordinates: [-68.0437, -38.9748], name: 'Dispositivo 9' },
  { id: 10, coordinates: [-68.0532, -38.9431], name: 'Dispositivo 10' },
];

    // Capa vectorial
    const vectorSource = new VectorSource();

    waypoints.forEach((wp) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat(wp.coordinates)),
        name: wp.name,
        id: wp.id,
      });

      feature.setStyle(
        new Style({
          image: new Icon({
            src: 'https://cdn-icons-png.flaticon.com/512/684/684908.png', // Icono para los waypoints
            scale: 0.05,
          }),
        })
      );

      vectorSource.addFeature(feature);
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    map.addLayer(vectorLayer);

    // Configura la interacción de selección para los waypoints
    const selectClick = new Select({
      condition: click,
    });

    map.addInteraction(selectClick);

    // Función para generar datos aleatorios
    const generateSensorData = () => ({
      sensor1: Math.floor(Math.random() * 100), // porcentaje
      sensor2: (Math.random()).toFixed(2), // número decimal
    });

    // Evento para manejar el click en el waypoint
    selectClick.on('select', (event) => {
      const selectedFeature = event.selected[0];

      if (selectedFeature) {
        const coordinates = selectedFeature.getGeometry().getCoordinates();
        const name = selectedFeature.get('name');
        const id = selectedFeature.get('id');
        const { sensor1, sensor2 } = generateSensorData(); // Genera datos aleatorios para los sensores

        // Muestra el popup con información detallada
        const content = document.getElementById('popup-content');
        content.innerHTML = `
          <h3>${name}</h3>
          <p><strong>Estado:</strong> <span style="color: green;">&#x2714; Online</span></p>
          <p><strong>Sensor 1:</strong> ${sensor1}% 
            <div class="progress-bar">
              <div class="progress" style="width: ${sensor1}%;"></div>
            </div>
          </p>
          <p><strong>Sensor 2:</strong> ${sensor2}</p>
          <p><strong>Actuadores:</strong></p>
          <div id="actuators">
            ${this.actuators[id].map((actuator, index) => `
              <label>
                <input type="checkbox" data-index="${index}" data-device="${id}" ${actuator ? 'checked' : ''}/> Actuador ${index + 1}
              </label>
            `).join('')}
          </div>
        `;

        // Añade los listeners a los actuadores
        document.querySelectorAll('#actuators input').forEach((checkbox) => {
          checkbox.addEventListener('change', (e) => {
            const index = e.target.getAttribute('data-index');
            const deviceId = e.target.getAttribute('data-device');
            this.actuators[deviceId][index] = e.target.checked;
          });
        });

        popup.setPosition(coordinates);
      } else {
        popup.setPosition(undefined); // Cierra el popup si se hace clic fuera
      }
    });

    // Configura el cierre del popup
    const closer = document.getElementById('popup-closer');
    closer.onclick = function () {
      popup.setPosition(undefined);
      closer.blur();
      return false;
    };



  },
}

</script>

<style>
#map {
  width: 100%;
  height: 100vh;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  cursor: pointer;
}

.progress-bar {
  width: 100%;
 
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 5px;
}

.progress {
  height: 100%;
 
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div ref="map" class="flex-fill" style="height: 91vh"></div>
      </div>
    </div>
  </div>
  <div id="popup" class="ol-popup">
    <a href="#" id="popup-closer" class="ol-popup-closer">X</a>
    <div id="popup-content"></div>
  </div>
</template>


